@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

$primary-color: #4db282;
$blueish: #13123d;
$white: #fff;
$poppins: "Poppins", sans-serif;
* {
    font-family: $poppins;
}
*:focus {
    outline: none;
}
.text-link,
.text-link:hover {
    text-decoration: none;
    color: $primary-color;
}
.input-group-text {
    background: #fff;
    border-color: #696969;
    border-left: 0;
}
.input-group input {
    border-right: 0 !important;
}

ul.my-list {
    list-style: none;
    li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 20px;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: $primary-color;
        }
    }
}
.section-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 65px;
    color: $blueish;
}
.section-gap {
    padding: 100px 0;
}
.btn-main {
    background: $primary-color;
    color: $white;
    border: 2px solid $primary-color;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;
    border-radius: 64px;
    text-transform: capitalize;
    &:hover {
        border: 2px solid $primary-color;
        color: $primary-color;
    }
    &.btn-sm {
        font-size: 14px;
        text-decoration: none;
        font-weight: 500;
        padding: 8px 20px;
        &:hover {
            border: 2px solid $primary-color;
            color: $white;
        }
    }
}
.btn-outline {
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 30px;
    color: #212121;
    background-color: #ddd;
    border: 2px solid #ddd;
    &:hover {
        color: #212121;
    }
}
.navbar__wrapper {
    position: fixed;
    width: 100%;
    padding: 15px 0;
    background: #fff;
    z-index: 9999999999;
    &.fixed {
        z-index: 9999999999;
        box-shadow: 0 0 20px 2px rgba(145, 145, 145, 0.29);
    }
    .navbar-expand .navbar-nav {
        align-items: center;
    }
    .navbar-brand {
        font-weight: 600;
        font-size: 22px;
        line-height: 21px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #8b8b8b;
        img {
            padding-right: 10px;
        }
    }
    .nav-link.call-link {
        font-weight: 600;
        font-size: 22px;
        margin-right: 20px;
        color: #8b8b8b;
        img {
            padding-right: 10px;
        }
    }
    .btn-main {
        margin-left: 30px;
    }
}
.main-banner {
    padding-top: 100px;
    .row {
        align-items: center;
    }
    .banner-img {
        text-align: right;
    }
    .banner-text {
        margin-top: 40px;
        h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 42px;
            text-transform: uppercase;
            color: $blueish;
            position: relative;
            padding-left: 30px;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 13px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                background: $blueish;
            }
        }
        h1 {
            position: relative;
            font-weight: 700;
            font-size: 60px;
            line-height: 76px;

            color: #343434;
            span {
                color: $blueish;
                display: block;
            }
        }
    }
    .drp-icon {
        padding: 5px 8px;
    }
}
.two-col-hov-wrapper {
    .row .col-lg-6:nth-child(3) .single-col-hov,
    .row .col-lg-6:nth-child(4) .single-col-hov {
        margin-bottom: 0px;
    }
    .single-col-hov {
        margin: 0 auto;
        position: relative;
        margin-bottom: 30px;
        img {
            height: 481px;
            width: 100%;
            object-fit: cover;
        }
        .single-col-desc {
            background: $blueish;
            padding: 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            h5 {
                font-size: 24px;
                line-height: 49px;
                letter-spacing: 0.05em;
                color: $white;
            }
            p {
                color: $white;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}

.footer_wrapper {
    background: #2c2d30;
    padding: 15px 0;
    p {
        color: $white;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0;
        span {
            color: $primary-color;
        }
    }
    .ft-links {
        text-align: right;
        a {
            color: $white;
            text-decoration: none;
            padding-left: 30px;
        }
    }
}

.about-feature-wrapper {
    p {
        font-weight: 500;
        font-size: 16px; 
        text-align: center;
        color: #7d7d7d;
        margin-bottom: 10px;
    }
    .arrow-feature {
        margin-top: 30px;
        h4 {
            font-weight: 600;
            font-size: 28px;
            line-height: 44px;
            color: $blueish;
            width: 80%;
            position: relative;
            &::after {
                content: url("../Images/Arrow.png");
                position: absolute;
            }
        }
    }
    .col-lg-3:nth-child(odd) .arrow-feature h4:after {
        top: -120px;
        right: -90px;
    }
    .col-lg-3:nth-child(even) .arrow-feature h4:after {
        top: 30px;
        right: -90px;
        transform: scaleY(-1);
    }
    .col-lg-3:last-child .arrow-feature h4:after {
        content: none;
    }
}

.fleet-wrapper {
    .container {
        width: 60%;
    }
    .fleet-box {
        margin: 0 auto;
        width: 90%;
        margin-bottom: 50px;
        text-align: center;
        background: #fbfcff;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 30px;
        img {
            width: 330px;
            height: 160px;
            object-fit: contain;
        }
        h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #565656;
            margin-bottom: 30px;
        }
        .capacity-wrap {
            display: flex;
            gap: 30px;
            justify-content: center;
            align-content: center;
            font-size: 24px;
            color: #7e7e80;
            line-height: 45px;
            img {
                width: 24px;
                margin-right: 10px;
                height: auto;
            }
        }
    }
}

.get-touch-wrapper {
    background: #edf1ff;
    padding: 100px 0;
    .map-frame {
        width: 100%;
        height: 700px;
        border-radius: 30px;
    }
}

.call-to-book-wrapper .container {
    background: $blueish;
    border-radius: 30px;
    position: relative;
    margin-top: 90px;
    margin-bottom: 60px;
    padding: 30px;
    .car-name {
        position: absolute;
        top: -100px;
        width: 350px;
    }
    h5 {
        color: $white;
        font-weight: 400;
        margin-bottom: 30px;
        font-size: 28px;
    }
    a {
        padding-left: 30px;
        font-weight: 600;
        font-size: 36px;
        color: $white;
        text-decoration: none;
    }
}

.touch-form {
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 60px;
    h4 {
        font-weight: 400;
        font-size: 36px;
        line-height: 56px;

        text-transform: capitalize;

        color: #273240;
    }
    input {
        height: 55px;

        border: 1px solid #dedede;
        border-radius: 6px;
    }
    .btn-main {
        width: 100%;
    }
}
.quote-form {
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 30px;
    margin-top: 10px;
    h4 {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;
        color: #273240;
    }

    select,
    input {
        height: 55px;
        border: 1px solid #696969;
        border-radius: 6px;
        &.pac-target-input {
            border-right: 0;
            width: 91%;
            padding-left: 15px;
        }
    }
    .btn-main {
        width: 50%;
        margin-top: 15px;
    }
    .nav-tabs {
        gap: 10px;
        border-bottom: 0;
        .nav-item {
            width: 48%;
            margin-bottom: 20px;
        }
        .nav-link {
            border: 3px solid #b9b9b9;
            border-radius: 32px;
            font-weight: 600;
            font-size: 18px;
            line-height: 17px;
            color: #b9b9b9;
            padding: 15px 32px;
            width: 100%;
            &.active {
                color: $primary-color;
                border: 3px solid $primary-color;
            }
        }
    }
    select {
        border-right: 0;
    }
    label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #6d6d6d;
    }
}

.inner-banner {
    position: relative;
    top: 160px;
    .ride-main-details {
        background: #ffffff;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
        padding: 25px;
        border-radius: 20px;
    }
    .ride-info {
        position: absolute;
        left: 150px;
        top: 6%;
    }
    h1 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 16px;
        font-weight: 400;
    }
    .route-info {
        margin-bottom: 20px;
        p {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 400;
            position: relative;
            padding-left: 25px;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 7px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: #e74c3c;
            }
            &:first-child::after {
                background: $primary-color;
            }
        }
    }
    .step-count {
        display: flex;
        gap: 20px;
        padding-top: 20px;
        margin-bottom: 30px;
        p {
            margin-bottom: 0;
            span {
                width: 32px;
                height: 32px;
                background: $blueish;
                display: inline-block;
                color: $white;
                text-align: center;
                line-height: 32px;
                border-radius: 50%;
                font-size: 16px;
            }
            &.active {
                font-weight: 500;
                font-size: 18px;
                span {
                    background-color: $primary-color;
                    margin-right: 10px;
                    font-size: 16px;
                }
            }
        }
    }
    .distance-duration {
        display: flex;
        justify-content: space-between;
    }
    .selected-vehicle {
        display: flex;
        gap: 30px;
        align-items: center;
        align-content: center;
        height: 50px;
        margin-top: 20px;
        img {
            width: 150px !important;
        }
        .capacity-wrap {
            margin: 0 !important;
            img {
                width: 30px !important;
            }
        }
    }
    .date-time-wrapper {
        display: flex;
        gap: 30px;
        div {
            padding: 5px 25px;
            border: 2px solid $blueish;
            border-radius: 10px;
            background-color: #fff;
        }

        .date-wrap {
            font-size: 36px;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 10px;
            span {
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                strong {
                    display: block;
                }
            }
        }
        .time-wrap {
            text-align: center;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            strong {
                font-size: 22px;
                display: block;
            }
        }
    }
    .selected-vehicle {
        h6 {
            font-weight: 400;
            color: #565656;
            font-size: 16px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 28px;
            font-weight: 600;
            text-transform: uppercase;
            color: $blueish;
            margin-bottom: 30px;
        }
        img {
            width: 100%;
        }
        .capacity-wrap {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            gap: 30px;
            font-weight: 600;
            color: #444;
            margin-top: 30px;
            span img {
                padding-right: 10px;
                width: auto;
            }
        }
    }
}

.vehicle-select-wrapper {
    .container {
        width: 70% !important;
    }
    .single-vehicle-select {
        border-bottom: 1px dashed #888;

        padding: 40px 0;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
        h3 {
            font-size: 28px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .capacity-wrap {
            justify-content: center;
            display: flex;
            // flex-direction: column;
            gap: 10px;
            font-weight: 600;
            color: #444;
            align-items: center;
            span {
                margin-right: 30px;
                img {
                    margin-right: 10px;
                    width: 24px;
                }
            }
        }
        ul {
            padding-left: 0;
        }
        img.vehicle-show {
            width: 100%;
            height: 120px;
            object-fit: contain;
        }
    }
    .trip-price-wrapper {
        background: #edf1ff;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        float: right;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        h2 {
            font-weight: 700;
            font-size: 28px;
            color: $blueish;
            sub {
                font-size: 13px;
                padding-left: 10px;
            }
        }
        .btn-main {
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            margin-top: 20px;
            display: block;
            width: 100%;
        }
    }
}

.booking-form-wrapper {
    margin-top: 40px;
    .booking-form {
        width: 80%;
        margin: 0 auto;
        padding: 40px;
        border-radius: 15px;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
        h4 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 10px;
        }
        input,
        select {
            height: 55px;
            border: 1px solid #696969;
            border-radius: 6px;
        }
        textarea {
            border: 1px solid #696969;
            border-radius: 6px;
        }
        .btn-main {
            width: 100%;
        }
    }
}

.terms-wrapper {
    margin-top: 150px;
    padding-bottom: 80px;
    h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 30px;

        color: $blueish;
    }
    p {
        line-height: 28px;
    }
    h4 {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 600;
        color: $blueish;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    margin-left: 20px;
    input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border-radius: 34px;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 21px;
        width: 21px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
    }

    input[type="checkbox"]:checked + .slider {
        background-color: $primary-color;
    }

    input[type="checkbox"]:checked + .slider:before {
        transform: translateX(27px);
    }
}

.centered-modal {
    .modal-dialog {
        top: 30%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }
}
.thankyou-banner {
    background: #edf1ff;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
        width: 350px;
        height: 250px;
        object-fit: contain;
        margin-top: 50px;
    }
    h1 {
        margin-top: 30px;
        position: relative;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 25px;
        color: #343434;
    }
    p {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 55px;
    }
}
